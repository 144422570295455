<template>
  <div>
    <!-- ID -->
    <SharedInputError v-if="isUserIdError">
      {{ t("errors.input_uid") }}
    </SharedInputError>
    <Input
      v-model="bulldropInputValueId"
      :placeholder="t('errors.input_user_bulldrop_id')"
      size="l"
      type="number"
    />
  </div>
</template>

<script lang="ts" setup>
import Input from "~/components/Shared/Input/Input.vue";

const { t } = useI18n();
const productsStore = useProductsStore();
const { isUserIdError, bulldropInputValueId } = storeToRefs(productsStore);
</script>
