<template>
  <Popup :class="$style['form-order-user-data']">
    <PopupHeader
      :show-back-btn="true"
      @hide="$emit('hide')"
      @back="handleBack"
    >
      {{ t("forms.order_user_data.title") }}
    </PopupHeader>

    <OrderForm />

    <OrderBtnWrapper>
      <OrderButton
        :content="`${t('products.products_order.buy')} ${toLocalNumber(productsStore.totalCost)} ₽`"
        :disabled="!productsStore.isFormFieldsFilled"
        @click="productsStore.order"
      />
    </OrderBtnWrapper>
  </Popup>
</template>

<script lang="ts" setup>
import Popup from "~/components/Shared/Popup/Popup.vue";
import PopupHeader from "~/components/Shared/Popup/PopupHeader.vue";
import OrderForm from "~/components/Forms/FormOrderUserData/OrderForm.vue";
import OrderBtnWrapper from "~/components/Products/OrderBtnWrapper.vue";
import OrderButton from "~/components/Forms/FormOrderUserData/OrderButton.vue";

const { t } = useI18n();
const productsStore = useProductsStore();

const handleBack = () => {
  productsStore.showUserDataForm = false;
  productsStore.showOrderConfirmForm = true;
};
</script>

<style lang="scss" module>
.form-order-user-data {
  @media screen and (max-width: $sm) {
    padding-bottom: 120px !important;
  }
}
</style>
