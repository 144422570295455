<template>
  <div>
    <SharedInputError v-if="isUserIdError">
      {{ t("errors.standoff_id") }}
    </SharedInputError>
    <Input
      v-model="standoffInputValueId"
      :has-error="isUserIdError"
      :placeholder="t('forms.order_user_data.input_standoff_id')"
      size="l"
      type="number"
    />
  </div>

  <div>
    <SharedInputError v-if="isUserAvatarError">
      {{ t("errors.game_avatar") }}
    </SharedInputError>

    <Uploader
      :has-limit="false"
      :default-imag="defaultSteamAvatar"
      :uploader-class="$style.gameAvatarUploader"
      @change="imgChange"
    />
  </div>

  <div>
    <p :class="$style['form-order-text']">
      {{ $t("forms.order_user_data.skin") }}
    </p>
    <StandoffSkin
      v-if="settings"
      :src="settings?.skinPicture || ''"
      :description="settings?.skinName || ''"
    />
  </div>
  <div>
    <p :class="$style['form-order-text']">
      {{ $t("forms.order_user_data.skin_price") }}
    </p>
    <CopyField :value="skinPrice || 0" />
  </div>
</template>

<script lang="ts" setup>
import Input from "~/components/Shared/Input/Input.vue";
import Uploader from "~/components/Shared/Uploader/Uploader.vue";
import CopyField from "./StandoffForm/CopyField.vue";
import StandoffSkin from "./StandoffForm/StandoffSkin.vue";
import type { StandoffOrderSettings, StandoffProduct } from "~/api_gen";

const { t } = useI18n();
const productsStore = useProductsStore();
const {
  standoffInputValueId,
  inputAvatar,
  isUserMailError,
  isUserIdError,
  isUserAvatarError,
  isError,
  errorText,
  surplus,
  defaultSteamAvatar,
} = storeToRefs(productsStore);

const settings = ref<StandoffOrderSettings>();

const sum = computed(() => {
  if (!productsStore.gameData) {
    return 0;
  }

  return (
    productsStore.gameData.products as {
      amount: number;
      data: StandoffProduct;
    }[]
  ).reduce((res, { amount, data: { gold } }) => res + amount * gold, 0);
});

const skinPrice = computed(() => {
  if (!settings.value) {
    return 0;
  }
  if (!sum.value) {
    return 0;
  }

  const commission = settings.value.marketFee * sum.value;
  const skinPrice = useBankersRounding(sum.value + commission + surplus.value);

  return skinPrice;
});

const data = await productsStore.orderStandoffSettings();
if (data) {
  settings.value = data;
}

const imgChange = (file: File) => (inputAvatar.value = file);

onUnmounted(() => {
  standoffInputValueId.value = "";
  inputAvatar.value = undefined;
  isUserMailError.value = false;
  isUserIdError.value = false;
  isUserAvatarError.value = false;
  isError.value = false;
  errorText.value = "";
});
</script>

<style lang="scss" module>
.form-order-select {
  @media screen and (max-width: $sm) {
    top: auto;
    bottom: 100%;
  }
}
.form-order-text {
  margin-bottom: 10px;
}
.gameAvatarUploader {
  height: 68px;
}
</style>
