<template>
  <div>
    <!-- ID -->
    <SharedInputError v-if="isUserIdError">
      {{ t("errors.input_user_id") }}
    </SharedInputError>
    <Input
      v-model.trim="moogoldInputValueId"
      :has-error="isUserIdError"
      :placeholder="t('forms.order_user_data.input_id')"
      size="l"
      type="number"
    />
  </div>
  <div>
    <!-- MooGold server -->
    <Select
      v-if="productsStore.moogoldGameServers.length"
      :values="productsStore.moogoldGameServers"
      :default-value="gameServerId"
      :not-selected-text="productsStore.moogoldGameServers[0].name"
      :select-values-classes="[$style['form-order-select']]"
      @change="changedServers"
    />
  </div>
</template>

<script lang="ts" setup>
import Input from "~/components/Shared/Input/Input.vue";
import Select from "~/components/Shared/Select/Select.vue";

const { t } = useI18n();
const productsStore = useProductsStore();
const {
  isUserMailError,
  isUserIdError,
  isError,
  errorText,
  moogoldInputValueId,
  gameServerId,
} = storeToRefs(productsStore);

await productsStore.getMoogoldServersList(
  productsStore.gameData!.id.toString(),
);

const changedServers = async (data: number) => {
  gameServerId.value = data;
};
onUnmounted(() => {
  isUserMailError.value = false;
  isUserIdError.value = false;
  isError.value = false;
  errorText.value = "";
  moogoldInputValueId.value = "";
  gameServerId.value = null;
});
</script>

<style lang="scss" module>
.form-order-select {
  @media screen and (max-width: $sm) {
    top: auto;
    bottom: 100%;
  }
}
</style>
