<template>
  <div :class="$style.orderForm">
    <div v-if="productsStore.isEmailFieldRequired">
      <SharedInputError v-if="isUserMailError && !userStore.userData?.email">
        {{ t("errors.input_user_mail") }}
      </SharedInputError>
      <Input
        v-model="inputValueMail"
        :has-error="isUserMailError"
        :placeholder="t('errors.input_user_mail')"
        size="l"
      />
    </div>
    <BulldropForm
      v-if="productsStore.gameData?.resourcetype === 'BulldropGame'"
    />
    <MoogoldForm
      v-else-if="productsStore.gameData?.resourcetype === 'SupplyGame'"
    />
    <StandoffForm
      v-if="productsStore.gameData?.resourcetype === 'StandoffGame'"
    />
    <PubgForm v-else-if="productsStore.gameData?.resourcetype === 'PUBGGame'" />
    <div>
      <GeneralError v-if="isError">
        {{ t("errors.order_error") }}
      </GeneralError>
      <GeneralError v-if="errorText">
        {{ errorText }}
      </GeneralError>
    </div>
  </div>
</template>
<script setup lang="ts">
import Input from "~/components/Shared/Input/Input.vue";
import BulldropForm from "~/components/Forms/FormOrderUserData/BulldropForm.vue";
import MoogoldForm from "~/components/Forms/FormOrderUserData/MoogoldForm.vue";
import StandoffForm from "~/components/Forms/FormOrderUserData/StandoffForm.vue";
import PubgForm from "~/components/Forms/FormOrderUserData/PubgForm.vue";
import GeneralError from "~/components/Forms/FormOrderUserData/GeneralError.vue";

const { t } = useI18n();
const productsStore = useProductsStore();
const userStore = useUserStore();
const { inputValueMail, isUserMailError, isError, errorText } =
  storeToRefs(productsStore);

watchEffect(() => {
  productsStore.initOrderFormValues();
});

onUnmounted(() => {
  isUserMailError.value = false;
  isError.value = false;
  errorText.value = "";
});
</script>
<style lang="scss" module>
.orderForm {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
